import { logoMapper } from '../../helpers'
export default () => {
  const data = [
    {
      address: '0x410D1e2154a019Da20894D15751386aa1FeebCb3',
      claimId: '0',
      earned: '2.35',
      coverCurrencyDisplay: 'ETH',
      coverId: '2454',
      value: '30',
      coverStatus: '0',
      expirationTimestamp: '1609524687',
      insuranceContractAddress: '0x1337DEF1e9c7645352D93baf0b789D04562b4185',
      insuranceContractName: 'arNFT v2',
      logo: 'eth.png',
      name: 'TEST',
      tokenIndex: '2454',
      apy: '5.45',
      balance: 35,
      assets: 75,
      underlyingTokenBalance: 0,
    },
  ]
  return data
}

export const vaults = {
  yvDai: {
    logo: logoMapper('Dai'),
    shieldAddress: '0xb8FC81379E04a38462E1a4618f4f77ff5488E3dD',
    underlyingTokenAddress: '0xdA816459F1AB5631232FE5e97a05BBBb94970c95',
    armorTokenAddress: '0x7c50A85BEd0A9a61C946487b603Bd266420dAE18',
  },
  yvSNX: {
    logo: logoMapper('Synthetix'),
    shieldAddress: '0xBdB4D10997185ed3CbD8a56f6F65A30d58a01384',
    underlyingTokenAddress: '0xF29AE508698bDeF169B89834F76704C3B205aedf',
    armorTokenAddress: '0x4c2F3e16B96351b2ec5B5154539641874433f395',
  },
  yvsUSD: {
    logo: logoMapper('USD'),
    shieldAddress: '0x97cdf2eBB98e5CC6df46720f1B2046A72cf1a490',
    underlyingTokenAddress: '0xa5cA62D95D24A4a350983D5B8ac4EB8638887396',
    armorTokenAddress: '0xb2c6f15D0914abd454A0f85dFeE3644ce391d893',
  },
  yvYFI: {
    logo: logoMapper('YFI'),
    shieldAddress: '0xCC91ECCaD007CE4c26dbA3872276E0E44aEaE22f',
    underlyingTokenAddress: '0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1',
    armorTokenAddress: '0x811614dAAc8f37EDf25a86F3bDE774B1e886ccF5',
  },
  yv1INCH: {
    logo: logoMapper('1Inch'),
    shieldAddress: '0xB5c0Eeaf86f43a5890600f37F19934F092a975CA',
    underlyingTokenAddress: '0xB8C3B7A2A618C552C23B1E4701109a9E756Bab67',
    armorTokenAddress: '0x9c868b4bE885F7716820104B4C76C2c1CCB8908C',
  },
}
