const rcaController = (address) =>
  Object.freeze({
    address: address,
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_governor',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_guardian',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_priceOracle',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_capOracle',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_apr',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_discount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_withdrawalDelay',
            type: 'uint256',
          },
          {
            internalType: 'address payable',
            name: '_treasury',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'rcaShield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'Mint',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'oldOracle',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOracle',
            type: 'address',
          },
        ],
        name: 'NewCapOracle',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'oldGuardian',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newGuardian',
            type: 'address',
          },
        ],
        name: 'NewGuardian',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'oldOracle',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOracle',
            type: 'address',
          },
        ],
        name: 'NewPriceOracle',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousGovernor',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newGovernor',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'rcaShield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'Purchase',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'rcaShield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'rcaAmount',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'RedeemFinalize',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'rcaShield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'rcaAmount',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'RedeemRequest',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'rcaShield',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'underlyingToken',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            indexed: false,
            internalType: 'string',
            name: 'symbol',
            type: 'string',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        name: 'ShieldCreated',
        type: 'event',
      },
      {
        inputs: [],
        name: 'apr',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: '_tokens',
            type: 'address[]',
          },
        ],
        name: 'balanceOfs',
        outputs: [
          {
            internalType: 'uint256[]',
            name: 'balances',
            type: 'uint256[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'capOracle',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'capacitiesRoot',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'discount',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'governor',
        outputs: [
          {
            internalType: 'address payable',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'guardian',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'uint128[]',
            name: '_protocols',
            type: 'uint128[]',
          },
          {
            internalType: 'uint128[]',
            name: '_percents',
            type: 'uint128[]',
          },
        ],
        name: 'initializeShield',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'isGov',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'lastShieldUpdate',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'liqRoot',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_uAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_capacity',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_capacityProof',
            type: 'bytes32[]',
          },
          {
            internalType: 'uint256',
            name: '_newCumLiq',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_liqProof',
            type: 'bytes32[]',
          },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'percentPaused',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'priceOracle',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'priceRoot',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_ethPrice',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_priceProof',
            type: 'bytes32[]',
          },
          {
            internalType: 'uint256',
            name: '_newCumLiq',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_liqProof',
            type: 'bytes32[]',
          },
        ],
        name: 'purchase',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'receiveOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_to',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_rcaAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_newCumLiq',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_liqProof',
            type: 'bytes32[]',
          },
        ],
        name: 'redeemFinalize',
        outputs: [
          {
            internalType: 'bool',
            name: 'zapper',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_user',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_rcaAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_newCumLiq',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_liqProof',
            type: 'bytes32[]',
          },
        ],
        name: 'redeemRequest',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_newApr',
            type: 'uint256',
          },
        ],
        name: 'setApr',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_newCapOracle',
            type: 'address',
          },
        ],
        name: 'setCapOracle',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'bytes32',
            name: '_newCapacitiesRoot',
            type: 'bytes32',
          },
        ],
        name: 'setCapacities',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_newDiscount',
            type: 'uint256',
          },
        ],
        name: 'setDiscount',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_newGuardian',
            type: 'address',
          },
        ],
        name: 'setGuardian',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'bytes32',
            name: '_newLiqRoot',
            type: 'bytes32',
          },
        ],
        name: 'setLiqTotal',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_newPercentPaused',
            type: 'uint256',
          },
        ],
        name: 'setPercentPaused',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_newPriceOracle',
            type: 'address',
          },
        ],
        name: 'setPriceOracle',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'bytes32',
            name: '_newPriceRoot',
            type: 'bytes32',
          },
        ],
        name: 'setPrices',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address payable',
            name: '_newTreasury',
            type: 'address',
          },
        ],
        name: 'setTreasury',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_newWithdrawalDelay',
            type: 'uint256',
          },
        ],
        name: 'setWithdrawalDelay',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_newZapper',
            type: 'address',
          },
        ],
        name: 'setZapper',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'shieldMapping',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'shieldProtocolPercents',
        outputs: [
          {
            internalType: 'uint128',
            name: 'protocolId',
            type: 'uint128',
          },
          {
            internalType: 'uint128',
            name: 'percent',
            type: 'uint128',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'systemUpdates',
        outputs: [
          {
            internalType: 'uint32',
            name: 'liqUpdate',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'pausedUpdate',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'withdrawalDelayUpdate',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'discountUpdate',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'aprUpdate',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'treasuryUpdate',
            type: 'uint32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address payable',
            name: 'newGovernor',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'treasury',
        outputs: [
          {
            internalType: 'address payable',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_capacity',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_proof',
            type: 'bytes32[]',
          },
        ],
        name: 'verifyCapacity',
        outputs: [],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_newCumLiq',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_liqProof',
            type: 'bytes32[]',
          },
        ],
        name: 'verifyLiq',
        outputs: [],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_shield',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_value',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_proof',
            type: 'bytes32[]',
          },
        ],
        name: 'verifyPrice',
        outputs: [],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'withdrawalDelay',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'zappers',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
  })

export default rcaController
