import { fromWei } from 'web3-utils'
import { RCAVaultsEvent } from './rcaVaultsEvent'
import Erc20Contract from '../erc20/erc20Contract'

class RCAVaultsContract {
  constructor(service, prefix, underlyingTokenAddress, rcaShieldAddress) {
    this.service = service
    this.prefix = prefix
    this.underlyingTokenAddress = underlyingTokenAddress
    this.rcaShieldAddress = rcaShieldAddress
    this.underlyingTokenContract = new Erc20Contract(
      service,
      `${prefix}.UnderlyingToken`,
      this.underlyingTokenAddress
    )
  }

  shouldDispatch(payload) {
    return this.service.shouldDispatch(RCAVaultsEvent(this.prefix), payload)
  }

  async dispatch(payload) {
    switch (payload.type) {
      case `${this.prefix}.Mint`:
        await this.mint(payload)
        break
      case RCAVaultsEvent.GetBalance:
        await this.getBalance(payload)
        break
      case `${this.prefix}.GetShieldBalance`:
        await this.getShieldBalance()
        break
      case `${this.prefix}.GetUnderlyingTokenBalance`:
        await this.getUnderlyingTokenBalance(payload)
        break
      case `${this.prefix}.GetRcaTokenValueOfPToken`:
        await this.getRcaTokenValueOfPToken(payload)
        break
      case `${this.prefix}.Withdrawal`:
        await this.withdraw(payload)
        break
      case `${this.prefix}.GetWithdrawRequests`:
        await this.getWithdrawRequests()
        break
      case `${this.prefix}.FinalizeWithdraw`:
        await this.finalizeWithdraw(payload)
        break
      // no default
    }
  }

  async mint(payload) {
    const account = this.service.getAccount()
    const web3 = await this.service.getWeb3()
    if (!account || !account.address) {
      return false
    }
    const { amount, capacity, capacityProof, cumLiq, liqProof } =
      payload.content

    let amountInWei = web3.utils.toWei(amount.toString(), 'ether')
    let capacityInWei = web3.utils.toWei(capacity.toString(), 'ether')

    let success = false
    await this.underlyingTokenContract
      .approve({
        content: {
          toAddress: this.rcaShieldAddress,
          amount: amountInWei,
        },
      })
      .then((resp) => {
        this.service.emit(
          RCAVaultsEvent(this.prefix).DepositApproveCompleted,
          resp
        )
        success = true
      })
      .catch((err) => {
        this.service.emitError(err)
        return false
      })

    if (!success) {
      return false
    }

    let response = await this._mint(
      account,
      amountInWei,
      capacityInWei,
      capacityProof,
      cumLiq,
      liqProof
    ).catch((err) => {
      this.service.emitError(err)
      return false
    })

    this.service.emit(RCAVaultsEvent(this.prefix).MintCompleted, response)
    return response
  }
  async _mint(account, amount, capacity, capacityProof, cumLiq, liqProof) {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.service.config.rcaShieldBase.address
        )

        await contract.methods
          .mintTo(
            account.address,
            amount,
            capacity,
            capacityProof,
            cumLiq,
            liqProof
          )
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((data) => {
            resolve(data)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  async withdraw(payload) {
    const account = this.service.getAccount()
    const web3 = await this.service.getWeb3()
    if (!account || !account.address) {
      return false
    }
    const { amount, cumLiq, liqProof } = payload.content

    let amountInWei = web3.utils.toWei(amount.toString(), 'ether')

    const contract = this.service.makeContract(
      web3,
      this.service.config.rcaShieldBase.abi,
      this.service.config.rcaShieldBase.address
    )

    let success = false
    await contract.methods
      .approve(this.rcaShieldAddress, amountInWei)
      .send({
        from: account.address,
      })
      .then((resp) => {
        this.service.emit(
          RCAVaultsEvent(this.prefix).WithdrawApproveCompleted,
          resp
        )
        success = true
      })
      .catch((err) => {
        this.service.emitError(err)
        return false
      })

    if (!success) {
      return false
    }

    let response = await this._handleWithdraw(
      account,
      web3.utils.toWei(amount.toString()),
      cumLiq,
      liqProof
    ).catch((err) => {
      this.service.emitError(err)
      return false
    })

    this.service.emit(RCAVaultsEvent(this.prefix).WithdrawalCompleted, response)
    return response
  }
  async _handleWithdraw(account, amount, cumLiq, liqProof) {
    return new Promise(async (resolve, reject) => {
      try {
        const self = this
        const web3 = await this.service.getWeb3()
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.service.config.rcaShieldBase.address
        )

        await contract.methods
          .redeemRequest(amount, cumLiq, liqProof)
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((response) => {
            resolve(response)
          })
      } catch (e) {
        return reject(e)
      }
    })
  }

  async getWithdrawRequests() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    let response = await this._getWithdrawRequests(account).catch((err) => {
      this.service.emitError(err)
      return '0'
    })

    this.service.setStore({
      [`${this.prefix}_WithdrawRequests`]: response,
    })
    this.service.emit(
      RCAVaultsEvent(this.prefix).WithdrawRequestsReturned,
      response
    )

    return response
  }
  async _getWithdrawRequests(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.service.config.rcaShieldBase.address
        )

        let request = await contract.methods
          .withdrawRequests(account.address)
          .call({ from: account.address })
        resolve(request)
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async finalizeWithdraw(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    const { cumLiq, liqProof } = payload.content

    let response = await this._finalizeWithdraw(
      account,
      cumLiq,
      liqProof
    ).catch((err) => {
      this.service.emitError(err)
      return '0'
    })

    this.service.emit(
      RCAVaultsEvent(this.prefix).FinalizeWithdrawCompleted,
      response
    )

    return response
  }
  async _finalizeWithdraw(account, cumLiq, liqProof) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.service.config.rcaShieldBase.address
        )

        await contract.methods
          .redeemTo(account.address, account.address, cumLiq, liqProof)
          .send({
            from: account.address,
          })
          .once('transactionHash', (hash) =>
            this.service.handleTransactionHash(hash)
          )
          .once('receipt', (receipt) => this.service.handleReceipt(receipt))
          .on('confirmation', (confirmationNumber, receipt) =>
            this.service.handleConfirmation(confirmationNumber, receipt)
          )
          .on('error', (error) => {
            this.service.handleError(error)
            reject(error)
          })
          .then((data) => {
            resolve(data)
          })
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async getShieldBalance() {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    let response = await this._getShieldBalance(account).catch((err) => {
      this.service.emitError(err)
      return '0'
    })

    if (response == null) {
      response = 0
    }

    this.service.setStore({
      [`${this.prefix}_ShieldBalance`]: response,
    })
    this.service.emit(
      RCAVaultsEvent(this.prefix).ShieldBalanceReturned,
      response
    )

    return response
  }
  async _getShieldBalance(account) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.service.config.rcaShieldBase.address
        )

        let balance = await contract.methods
          .balanceOf(account.address)
          .call({ from: account.address })
        resolve(web3.utils.fromWei(balance, 'ether'))
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async getUnderlyingTokenBalance(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }

    let address = this.underlyingTokenAddress
    if (payload != null && payload.content != null && payload.content.address) {
      address = payload.content.address
    }

    let response = await this._getUnderlyingTokenBalance(address).catch(
      (err) => {
        this.service.emitError(err)
        return '0'
      }
    )

    this.service.setStore({
      [`${this.prefix}_UnderlyingTokenBalance`]: {
        tokenAddress: address,
        tokenBalance: response,
      },
    })
    this.service.emit(
      RCAVaultsEvent(this.prefix).UnderlyingTokenBalanceReturned,
      {
        tokenAddress: address,
        tokenBalance: response,
      }
    )

    return response
  }
  async _getUnderlyingTokenBalance(address) {
    return new Promise(async (resolve, reject) => {
      try {
        let balance = await this.underlyingTokenContract.getBalance({
          content: { address: address },
        })
        resolve(fromWei(balance, 'ether'))
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async getRcaTokenValueOfPToken(payload) {
    const account = this.service.getAccount()
    if (!account || !account.address) {
      return '0'
    }
    const { pTokenAmount, cumLiq, liqProof } = payload.content

    let response = await this._getRcaTokenValueOfPToken(
      account,
      pTokenAmount,
      cumLiq,
      liqProof
    ).catch(() => {
      return '0'
    })

    this.service.setStore({
      [`${this.prefix}_RcaTokenValue`]: response,
    })
    this.service.emit(
      RCAVaultsEvent(this.prefix).RcaTokenValueOfPTokenReturned,
      response
    )

    return response
  }

  async _getRcaTokenValueOfPToken(account, pTokenAmount, cumLiq, liqProof) {
    const web3 = await this.service.getWeb3()
    return new Promise(async (resolve, reject) => {
      try {
        const contract = this.service.makeContract(
          web3,
          this.service.config.rcaShieldBase.abi,
          this.rcaShieldAddress
        )

        let balance = await contract.methods
          .rcaValue(pTokenAmount, cumLiq, liqProof)
          .call({ from: account.address })
        resolve(fromWei(balance, 'ether'))
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }
}

export default RCAVaultsContract
