import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_COVER,
  COVER_RETURNED,
} from '../../constants'
import Store from '../../stores/store'
import { withTheme } from 'styled-components'
import ActionModal from '../../components/common/actionModal/ActionModal'
import Container from '../../components/common/container/Container'
import { Title } from '../../components/common/Title'
import MOCKED_DATA from './mock'
import Deposits from './Deposits'
import Opportunities from './Opportunities'
import { RCAVaultsEvent } from '../../stores/contracts/rcaVaults/rcaVaultsEvent'
import WithdrawPending from './WithdrawPending'

const emitter = Store.emitter
const dispatcher = Store.dispatcher
const store = Store.store

const Dashboard = ({ t }) => {
  const [account, setAccount] = useState(null)
  const [cover, setCover] = useState([])
  const [deposits, setDeposits] = useState([])
  const [opportunities, setOpportunities] = useState([])
  const [modalText, setModalText] = useState('')
  const [isDepositing, setIsDepositing] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [withdrawRequest, setWithdrawRequest] = useState()

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)
    dispatcher.dispatch({ type: GET_COVER, content: {} })

    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected)
    emitter.on(CONNECTION_CONNECTED, connectionConnected)
    emitter.on(COVER_RETURNED, coverReturned)

    return () => {
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected)
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected)
      emitter.removeListener(COVER_RETURNED, coverReturned)
    }
  }, [])

  const dispatch = (prefix, type, content) => {
    if (content == null) {
      content = {}
    }
    dispatcher.dispatch({
      type: `${prefix}.${type}`,
      content: content,
    })
  }

  const dispatchUpdates = () => {
    dispatcher.dispatch({
      type: RCAVaultsEvent.GetBalance,
      content: {},
    })
  }

  const connectionConnected = () => {
    const _account = store.getStore('account')
    setAccount(_account)
    if (_account && _account.address)
      dispatcher.dispatch({ type: GET_COVER, content: {} })
    dispatchUpdates()
  }

  const connectionDisconnected = () => setAccount(null)

  const coverReturned = () => {
    // const _cover = store.getStore('cover')
    const _cover = MOCKED_DATA()
    sortDeposits(_cover, 'apy', 'desc')
    sortOpportunities(_cover, 'apy', 'desc')
    if (Array.isArray(_cover)) setCover(_cover)
  }

  const sortDeposits = (_dataArr, _sortBy, _sortDirection) => {
    const sortedArr = sortTableByName(_dataArr, _sortBy, _sortDirection)
    setDeposits(sortedArr)
  }

  const sortOpportunities = (_dataArr, _sortBy, _sortDirection) => {
    const sortedArr = sortTableByName(_dataArr, _sortBy, _sortDirection)
    setOpportunities(sortedArr)
  }

  const sortTableByName = (_dataArr, _sortBy, _sortDirection) => {
    const _sortedArr = _dataArr.sort((_x, _y) => {
      try {
        const x = isNaN(+_x[_sortBy]) ? _x[_sortBy].toLowerCase() : +_x[_sortBy]
        const y = isNaN(+_y[_sortBy]) ? _y[_sortBy].toLowerCase() : +_y[_sortBy]
        return y < x ? -1 : y > x ? 1 : 0
      } catch (e) {
        return 1
      }
    })
    return _sortDirection === 'desc' ? _sortedArr : _sortedArr.reverse()
  }

  const onWithdrawPending = (request) => {
    setWithdrawRequest(request)
  }

  const onFinalizeWithdraw = () => {
    setModalText('Waiting to finalize withdraw. This may take a few minutes.')
    setIsWithdrawing(true)
    dispatch(`RcaVault.${withdrawRequest.name}`, 'FinalizeWithdraw', {
      cumLiq: 0,
      liqProof: [
        '0x7dd430444208fec3b34e930b8f2fb5894bea03d321894494dbe6ac54d992a087',
      ],
    })
  }

  return (
    <Container noaccount={!account}>
      <Title>{t('Dashboard.Title')}</Title>
      <Deposits
        data={deposits}
        account={account}
        sort={(sortBy, sortDirection) =>
          sortDeposits(deposits, sortBy, sortDirection)
        }
        setModalText={setModalText}
        setIsDepositing={setIsDepositing}
        setIsWithdrawing={setIsWithdrawing}
        withdrawPending={onWithdrawPending}
      />
      {withdrawRequest && (
        <WithdrawPending
          onWithdraw={() => onFinalizeWithdraw()}
          withdrawalRequest={withdrawRequest}
        />
      )}
      <Opportunities
        data={opportunities}
        account={account}
        sort={(sortBy, sortDirection) =>
          sortOpportunities(cover, sortBy, sortDirection)
        }
        setModalText={setModalText}
        setIsDepositing={setIsDepositing}
        setIsWithdrawing={setIsWithdrawing}
      />
      <ActionModal
        closeModal={false}
        actionText={modalText}
        isModalOpened={isDepositing || isWithdrawing}
      />
    </Container>
  )
}

export default withTranslation()(withRouter(withTheme(Dashboard)))
