import React, { useState, useEffect } from 'react'
import { toWei } from 'web3-utils'
import TableRow from '@material-ui/core/TableRow'
import Store from '../../stores/store'
import { ERROR } from '../../constants'
import { ActionContainer } from '../../components/common/actionModal/styled'
import { AssetsTitle } from '../../components/common/AssetsTitle'
import NumberSelectionModal from '../../components/common/numberSelectionModal/NumberSelectionModal'
import StickyModal from '../../components/common/modal/StickyModal'
import {
  commas,
  removeEmitterListeners,
  turnOnEmitterListeners,
} from '../../helpers'
import RcaActionButtonCell from './RcaActionButtonCell'
import WithdrawOption from './WithdrawOption'
import { AssetsLogo, Cell } from './styled'

const dispatcher = Store.dispatcher
const emitter = Store.emitter
const store = Store.store

const VaultRow = ({
  account,
  columns,
  r,
  disableWithdraw,
  rca,
  withdrawPending,
  setModalText,
  isDepositing,
  setIsDepositing,
  isWithdrawing,
  setIsWithdrawing,
}) => {
  const [isShieldAssetsBalanceLoading, setIsShieldAssetsBalanceLoading] =
    useState(true)
  const [isShieldBalanceLoading, setIsShieldBalanceLoading] = useState(true)
  const [depositWillReceive, setDepositWillReceive] = useState(0.0)
  const [isUnderlyingTokenBalanceLoading, setIsUnderlyingTokenBalanceLoading] =
    useState(false)
  const [shieldBalance, setShieldBalance] = useState(0)
  const [underlyingTokenBalance, setUnderlyingTokenBalance] = useState(0)
  const [shieldAssetsBalance, setShieldAssetsBalance] = useState(0)
  const [rcaTokenValue, setRcaTokenValue] = useState(0.0)
  const eventPrefix = `RcaVault.${r.name}`
  const [isMintModalOpened, setIsMintModalOpened] = useState(false)
  const [isRedeemModalOpened, setIsRedeemModalOpened] = useState(false)
  const [request, setRequest] = useState({})

  const eventKey = (prefix, key) => {
    return `${prefix}.${key}`
  }

  const dispatch = (prefix, type, content) => {
    if (content == null) {
      content = {}
    }
    dispatcher.dispatch({
      type: `${prefix}.${type}`,
      content: content,
    })
  }

  useEffect(() => {
    if (account == null) {
      return
    }

    let events = [
      [ERROR, errorReturned],
      [eventKey(eventPrefix, `ShieldBalanceReturned`), onShieldBalanceReturned],
      [
        eventKey(eventPrefix, `UnderlyingTokenBalanceReturned`),
        onUnderlyingTokenBalanceReturned,
      ],
      [
        eventKey(eventPrefix, `RcaTokenValueOfPTokenReturned`),
        onRcaTokenValueOfPTokenReturned,
      ],
      [
        eventKey(eventPrefix, `DepositApproveCompleted`),
        onDepositApproveCompleted,
      ],
      [eventKey(eventPrefix, `MintCompleted`), onMintCompleted],
      [
        eventKey(eventPrefix, `WithdrawApproveCompleted`),
        onWithdrawApproveCompleted,
      ],
      [eventKey(eventPrefix, `WithdrawalCompleted`), onWithdrawalCompleted],
      [
        eventKey(eventPrefix, `WithdrawRequestsReturned`),
        onWithdrawRequestsReturned,
      ],
      [
        eventKey(eventPrefix, `FinalizeWithdrawCompleted`),
        onFinalizeWithdrawCompleted,
      ],
    ]
    turnOnEmitterListeners(emitter, events)

    dispatchBalanceEvents()

    dispatch(eventPrefix, 'GetRcaTokenValueOfPToken', {
      pTokenAmount: toWei('1', 'ether'),
      cumLiq: 0,
      liqProof: [
        '0x7dd430444208fec3b34e930b8f2fb5894bea03d321894494dbe6ac54d992a087',
      ], // fake liquidation proof
    })

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [account])

  const dispatchBalanceEvents = () => {
    setIsShieldBalanceLoading(true)
    dispatch(eventPrefix, 'GetShieldBalance')
    setIsUnderlyingTokenBalanceLoading(true)
    dispatch(eventPrefix, 'GetUnderlyingTokenBalance', {
      address: account.address,
    })
    setIsShieldAssetsBalanceLoading(true)
    dispatch(eventPrefix, 'GetUnderlyingTokenBalance', { address: r.address })
    dispatch(eventPrefix, 'GetWithdrawRequests', { address: account.address })
  }

  const errorReturned = () => {
    setIsWithdrawing(false)
    setIsDepositing(false)
  }

  const areEventsLoading = () => {
    return (
      isUnderlyingTokenBalanceLoading ||
      isShieldBalanceLoading ||
      isShieldAssetsBalanceLoading
    )
  }

  const isConnected = () => {
    return account && !areEventsLoading()
  }

  const getStore = (prefix, key) => {
    return store.getStore(`${prefix}_${key}`)
  }

  const onShieldBalanceReturned = () => {
    const _balance = getStore(eventPrefix, `ShieldBalance`)
    setShieldBalance(_balance)
    setIsShieldBalanceLoading(false)
  }

  const onUnderlyingTokenBalanceReturned = () => {
    const { tokenAddress, tokenBalance } = getStore(
      eventPrefix,
      `UnderlyingTokenBalance`
    )

    if (r.address === tokenAddress) {
      setShieldAssetsBalance(tokenBalance)
      setIsShieldAssetsBalanceLoading(false)
      return
    }

    setUnderlyingTokenBalance(tokenBalance)
    setIsUnderlyingTokenBalanceLoading(false)
    calculateWillReceive(tokenBalance)
  }

  const onRcaTokenValueOfPTokenReturned = () => {
    const _rcaToken = getStore(eventPrefix, `RcaTokenValue`)
    setRcaTokenValue(_rcaToken)
  }

  const calculateWillReceive = (balance) => {
    if (balance == null || balance <= 0) {
      setDepositWillReceive(0)
      return
    }

    let willReceive = rcaTokenValue > 0 ? balance * rcaTokenValue : balance
    setDepositWillReceive(willReceive)
  }

  const onMint = (amount) => {
    setModalText('Waiting for approval. This may take a few minutes.')
    setIsDepositing(true)
    dispatch(eventPrefix, `Mint`, {
      amount: amount.toString(),
      capacity: '1000000',
      capacityProof: [
        '0x64983626fafc8944970eb23e6768796e0cdaba0c70bbded2aa46f521a3beb2c5',
      ],
      cumLiq: 0,
      liqProof: [
        '0x7dd430444208fec3b34e930b8f2fb5894bea03d321894494dbe6ac54d992a087',
      ],
    })
  }

  const onDepositApproveCompleted = () => {
    setModalText('Waiting to deposit. This may take a few minutes.')
  }

  const onMintCompleted = () => {
    setIsDepositing(false)
    dispatchBalanceEvents()
  }

  const onWithdraw = () => {
    setModalText('Waiting for approval. This may take a few minutes.')
    setIsRedeemModalOpened(false)
    setIsWithdrawing(true)
    dispatch(eventPrefix, 'Withdrawal', {
      amount: shieldBalance.toString(),
      cumLiq: 0,
      liqProof: [
        '0x7dd430444208fec3b34e930b8f2fb5894bea03d321894494dbe6ac54d992a087',
      ],
    })
  }

  const onWithdrawApproveCompleted = () => {
    setModalText('Waiting to withdraw. This may take a few minutes.')
  }

  const onWithdrawalCompleted = (data) => {
    setIsWithdrawing(false)
    dispatchBalanceEvents()
  }

  const onWithdrawRequestsReturned = () => {
    let request = getStore(eventPrefix, `WithdrawRequests`)
    if (request === '0') return
    request['name'] = r.name
    setRequest(request)
    withdrawPending && withdrawPending(request)
  }

  const onFinalizeWithdrawCompleted = () => {
    setIsWithdrawing(false)
    dispatchBalanceEvents()
  }

  return (
    <>
      <TableRow>
        <AssetsLogo
          align="left"
          onClick={() =>
            window.open(`https://etherscan.io/address/${r.address}`, '_blank')
          }
        >
          <img src={require(`../../assets/${r.logo}`)} alt="asset icon" />
          <AssetsTitle>
            {r.name}
            {rca && '-RCA'}
          </AssetsTitle>
        </AssetsLogo>
        {columns.slice(1).map((column) => {
          if (column === 'balance') {
            return (
              <Cell key={column} noactive={parseFloat(r[column]) <= 0 ? 1 : 0}>
                {commas(5).format(shieldBalance)}
              </Cell>
            )
          }
          if (column === 'value') {
            return (
              <Cell key={column} noactive={parseFloat(r[column]) <= 0 ? 1 : 0}>
                ${parseFloat(shieldBalance * 0.001).toFixed(2)}
              </Cell>
            )
          }
          if (column === 'underlyingTokenBalance') {
            return (
              <Cell key={column}>
                {commas(5).format(underlyingTokenBalance)}
              </Cell>
            )
          }
          if (column === 'assets') {
            return (
              <Cell key={column} noactive={parseFloat(r[column]) <= 0 ? 1 : 0}>
                {shieldAssetsBalance}
              </Cell>
            )
          }
          return (
            <Cell key={column} noactive={parseFloat(r[column]) <= 0 ? 1 : 0}>
              {column === 'earned' && '$'}
              {parseFloat(r[column]).toFixed(2)}
              {column === 'apy' && '%'}
            </Cell>
          )
        })}
        <RcaActionButtonCell
          isConnected={isConnected()}
          disableWithdraw={disableWithdraw}
          onRedeem={() => {
            setIsRedeemModalOpened(true)
          }}
          onMint={() => {
            setIsMintModalOpened(true)
          }}
          isLoading={isDepositing || isWithdrawing}
        />
      </TableRow>
      <NumberSelectionModal
        isLoading={!isConnected()}
        isModalOpened={isMintModalOpened}
        closeModal={() => setIsMintModalOpened(false)}
        onSubmit={(amount) => onMint(amount)}
        title={'Deposit'}
        availableBalance={underlyingTokenBalance}
        onChange={(amount) => calculateWillReceive(amount)}
        inputText={r.name}
        actionText={'Deposit'}
        infoText={
          <>
            <div>
              <b>Available:</b> {commas(5).format(underlyingTokenBalance)}{' '}
              {r.name}
            </div>
            <div>
              <b>You will receive:</b> {commas(5).format(depositWillReceive)} ar
              {r.name.toUpperCase()}
            </div>
          </>
        }
        totalAvailable={underlyingTokenBalance}
        disablePercent={true}
      >
        <></>
      </NumberSelectionModal>
      <StickyModal
        closeModal={() => setIsRedeemModalOpened(false)}
        isModalOpened={isRedeemModalOpened}
        width={650}
      >
        <ActionContainer>
          <WithdrawOption
            tokenName={r.name}
            availableToWithdraw={shieldBalance}
            withdrawalRequest={request}
            onWithdrawWithDelay={() => onWithdraw()}
            onCancelModal={() => setIsRedeemModalOpened(false)}
          />
        </ActionContainer>
      </StickyModal>
    </>
  )
}

export default VaultRow
