import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import { CoverContainer } from '../../components/common/CoverContainer'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'

export const Box = styled(CoverContainer)`
  margin-top: 32px;
  overflow: hidden;
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 17px 0;
  flex-wrap: wrap;
  margin-top: 14px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`
export const TableContainerStyled = styled(TableContainer)`
  background: transparent;
  box-shadow: none;
  margin-top: 10px;
  & td {
    border: none;
  }
  & th {
    border: none;
  }
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  @media screen and (max-width: 900px) {
    max-width: ${(p) => (p.novalue ? '550px' : p.unstake ? '825px' : '800px')};
    width: 100%;
    margin: 23px auto 0;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent;
    }
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
`
export const TableStyled = styled(Table)`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 900px) {
    width: ${(p) => (p.novalue ? '550px' : p.unstake ? '825px' : '800px')};
    overflow-x: scroll;
  }
  @media screen and (max-width: 600px) {
    width: ${(p) => (p.novalue ? '500px' : p.unstake ? '775px' : '750px')};
  }
`
export const TitleCell = styled(TableCell)`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  border: none;
  color: ${(p) => p.theme.colors.secondary};
  padding: 15px;
  text-transform: ${(p) => (p.capitalize ? 'capitalize' : 'normal')};
  cursor: pointer;

  & svg {
    opacity: ${(p) => (p.showarrow ? 1 : 0)};
  }
  &:hover {
    & svg {
      opacity: ${(p) => (p.showarrow ? 1 : 0.3)};
    }
  }

  @media screen and (max-width: 600px) {
    line-height: 17px;
    padding: 8px 10px;
  }
`
export const ActionButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondaryDefault};
  border-radius: 6px;
  border: none;
  padding: 8px 12px;
  margin: 5px;
  max-width: 160px;
  overflow: hidden;

  &:hover {
    border: none;
    background: ${(p) => p.theme.colors.secondaryDefault};
  }
`
export const AssetsLogo = styled(TableCell)`
  display: flex;
  align-items: center;
  padding: 6px 15px;
  min-height: 52px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  & img {
    margin-right: 6px;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    overflow: hidden;
  }
  @media screen and (max-width: 600px) {
    padding: 5px 9px;
    min-height: 40px;
  }
`
export const Cell = styled(TableCell)`
  padding: ${(p) => (p.skeleton ? '6px 15px 11px' : '6px 15px')};
  min-height: 52px;
  font-weight: ${(p) => (p.noactive ? 'normal' : 'bold')};
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 19px;
  color: ${(p) =>
    p.noactive ? p.theme.colors._default : p.theme.colors.secondary};
  @media screen and (max-width: 600px) {
    padding: 6px 10px;
    min-height: 40px;
  }
  &.btnCell {
    padding: 3px 10px;
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button {
    max-height: 28px;
    padding: 6px;
  }
`
export const ActionCell = styled(Cell)`
  padding: 11px 10px 11px 0;
  @media screen and (max-width: 600px) {
    padding: 8px 8px 8px 0;
  }
`
export const Bold = styled.span`
  font-weight: bold;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 27px;
  background-color: ${(p) => p.theme.colors.defaultArrow};
  padding: 25px 15px;
  border-radius: 16px;
  align-items: stretch;
  color: #fff;
  @media screen and (max-width: 990px) {
    flex-wrap: wrap;
    padding: 0 15px 15px;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`
export const Content = styled.div`
  padding: 21px 10px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`
export const OptionContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  width: 100%;
  margin-top: 35px;

  @media screen and (max-width: 548px) {
    margin-top: 25px;
    max-width: 100%;
  }
`
export const OptionText = styled.div`
  max-width: 170px;
  width: 100%;
  margin: 15px auto 0;
`
export const ValueContent = styled.div`
  margin-top: 24px;
`
