import React from 'react'
import { withTheme } from 'styled-components'
import TooltipGuide from '../tooltipGuide/TooltipGuide'
import { BoxTitleText } from '../../common/BoxTitleText'

const BoxTitle = ({ theme, text, guideTooltipContent }) => {
  const { colors } = theme

  return (
    <BoxTitleText>
      {text}
      {guideTooltipContent && (
        <TooltipGuide text={guideTooltipContent} color={colors.disabledText} />
      )}
    </BoxTitleText>
  )
}

export default withTheme(BoxTitle)
