import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import Button from '../../components/shared/button/Button'
import { Container } from './styled'
import moment from 'moment'
import { fromWei } from 'web3-utils'
import { commas } from '../../helpers'

const WithdrawPending = ({ t, withdrawalRequest, onWithdraw }) => {
  useEffect(() => {
    isWithdrawalingWithDelay()
  }, [withdrawalRequest])

  const isWithdrawalingWithDelay = () => {
    return withdrawalRequest != null && withdrawalRequest.endTime > 0
  }

  const isAvailableToWithdraw = () => {
    if (withdrawalRequest == null) {
      return false
    }

    if (withdrawalRequest.endTime === 0) {
      return false
    }
    return (
      parseInt(withdrawalRequest.endTime) + 24 * 60 * 60 < moment.utc().unix()
    )
  }
  const withdrawalLockAmount = () => {
    if (withdrawalRequest == null || withdrawalRequest.uAmount == null) {
      return 0
    }

    return parseFloat(fromWei(withdrawalRequest.uAmount, 'ether'))
  }

  return (
    isWithdrawalingWithDelay() && (
      <Container>
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Attention!
          <br />{' '}
          {isAvailableToWithdraw() ? (
            <span>You can now withdraw your {withdrawalRequest.name}</span>
          ) : (
            <span>You have a pending withdrawal</span>
          )}
        </div>
        {isAvailableToWithdraw() && (
          <Button
            content={`${t('Dashboard.Withdraw')} ${commas(4).format(
              withdrawalLockAmount()
            )} ${withdrawalRequest.name}`}
            disabled={!isAvailableToWithdraw()}
            onClick={onWithdraw}
          />
        )}
        {!isAvailableToWithdraw() && (
          <div style={{ alignSelf: 'center' }}>
            You'll be able to withdraw your{' '}
            <strong>
              {commas(4).format(withdrawalLockAmount())}{' '}
              {withdrawalRequest.name}{' '}
              {moment.unix(withdrawalRequest.endTime).fromNow()}
            </strong>
          </div>
        )}
      </Container>
    )
  )
}

export default withTranslation()(withTheme(WithdrawPending))
