import React from 'react'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import BoxTitle from '../../components/shared/boxTitle/BoxTitle'
import Text from '../../components/shared/text/Text'
import Button from '../../components/shared/button/Button'
import {
  Content,
  ValueContent,
  OptionContent,
  OptionText,
  Bold,
} from './styled'
import moment from 'moment'
import { commas } from '../../helpers'
import { CancelButton } from '../../components/common/cancelButton'

const WithdrawOption = ({
  t,
  theme,
  tokenName,
  availableToWithdraw,
  onWithdrawWithDelay,
  onCancelModal,
  withdrawalRequest,
}) => {
  const { colors } = theme

  const isWithdrawalingWithDelay = () => {
    return withdrawalRequest != null && withdrawalRequest.endTime > 0
  }

  const availableTokenToWithdraw = () => {
    return availableToWithdraw * 1
  }

  const dateTimeAfterDelay = () =>
    moment()
      .add(24 * 60 * 1, 's')
      .utc()
      .format('MMM Do [at] HH:mm [UTC]')

  return (
    <Content>
      <BoxTitle text={t('Dashboard.Withdraw')} />
      <ValueContent>
        <Text
          text={`${commas(5).format(availableToWithdraw)} ${tokenName}-RCA`}
          size="lg"
          isBold={true}
          color={colors.primaryLightTrue}
        />
      </ValueContent>

      <OptionContent>
        <Button
          content="WITHDRAW WITH 24H DELAY"
          disabled={isWithdrawalingWithDelay()}
          onClick={onWithdrawWithDelay}
        />
        <OptionText>
          {isWithdrawalingWithDelay() ? (
            <Text
              text={`Only one withdrawal may be pending at a time`}
              isBold={false}
              color={colors.disabledText}
            />
          ) : (
            <Text
              text={
                <>
                  Receive{' '}
                  <Bold>
                    {availableTokenToWithdraw()} {tokenName}
                  </Bold>{' '}
                  on <Bold>{dateTimeAfterDelay()}</Bold>
                </>
              }
              color={colors.secondaryDefault}
            />
          )}
        </OptionText>
      </OptionContent>
      <CancelButton onClick={onCancelModal}>
        {t('ConfirmCoverModal.Cancel')}
      </CancelButton>
    </Content>
  )
}

export default withTranslation()(withTheme(WithdrawOption))
