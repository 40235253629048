import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  AssetsBox,
  AssetsGradient,
  AssetsTitle,
} from '../../../components/arNxm/styled'
import React from 'react'
import styled from 'styled-components'
import { commas } from '../../../helpers'

export const StatsWrapper = styled.div`
  margin: 27px auto 0;
  display: flex;
  justify-content: center;
  gap: 5%;
  @media screen and (max-width: 990px) {
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-around;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

const StatsPanel = ({ conversions, managedAssets }) => {
  return (
    <StatsWrapper>
      <AssetsBox mw="30">
        <AssetsTitle>
          Managed Assets
          <AssetsGradient>
            {commas(2).format(managedAssets)} ARMOR
          </AssetsGradient>
        </AssetsTitle>
      </AssetsBox>
      <AssetsBox mw="30">
        <AssetsTitle>
          Conversion
          <AssetsGradient>
            1 vARMOR: {commas(3).format(conversions.vArmorToArmor)} ARMOR
          </AssetsGradient>
        </AssetsTitle>
      </AssetsBox>
      <AssetsBox mw="30">
        <AssetsTitle>
          APY
          <AssetsGradient>
            {commas(2).format((500000 / managedAssets) * 52.149 * 100)}%
          </AssetsGradient>
        </AssetsTitle>
      </AssetsBox>
    </StatsWrapper>
  )
}

export default withTranslation()(withRouter(StatsPanel))
