import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Store from '../../stores/store'
import {
  ButtonText,
  Wrapper,
  NameText,
  FooterWrapper,
  FooterText,
  NameContainer,
  CurrentRow,
  CurrentTitle,
  TextContent,
  ClaimFooterText,
  ClaimFooterValue,
  LogoContainer,
  Logo,
  Column,
  Row,
  TooltipSpan,
  ColumnHead,
  FooterActionButton,
  InfoText,
  ActionWrapper,
  ActionButtonNew,
  ColoredValue,
  ValueInfo,
  TooltipLink,
} from './styled'
import {
  removeEmitterListeners,
  threeDigitFormatter,
  turnOnEmitterListeners,
  twoDigitFormatter,
  commas,
  formatETH,
  formatUSD,
} from '../../helpers'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../constants'
import StakeTokensModal from './StakeTokensModal'
import UnStakeTokensModal from './UnStakeTokensModal'
import AvailableWalletInfo from '../common/availableWalletInfo/AvailableWalletInfo'
import AboutInfoIcon from '../icons/AboutInfoIcon'
import TooltipGuide from '../common/tooltipGuide/TooltipGuide'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const BaseUtilizationFarmingCombined = ({
  t,
  theme,
  network,
  prefix,
  title,
  comingSoon = false,
  handleOpenTopupModal,
  handleOpenWithdrawModal,
  monthlyCost,
  ethBalance,
  arCoreCredit,
  ethPrice,
  isUsdPrimary,
}) => {
  const [account, setAccount] = useState(null)
  const [stakedArmor, setStakedArmor] = useState('0')
  const [apy, setApy] = useState('0')
  const [rewardsOwed, setRewardsOwed] = useState('0')
  const [tokenBalance, setTokenBalance] = useState(0.0)
  const [isStakeTokensModalOpened, setIsStakeTokensModalOpened] =
    useState(false)
  const [isUnStakeTokensModalOpened, setIsUnStakeTokensModalOpened] =
    useState(false)
  const { colors } = theme

  function dispatchUpdateEvents() {
    dispatcher.dispatch({
      type: `${prefix}_Token.GetBalance`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetStakedArmor`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetRewardsOwed`,
      content: {},
    })
    dispatcher.dispatch({
      type: `${prefix}_Farm.GetLastReward`,
      content: {},
    })
  }

  useEffect(() => {
    momentDurationFormatSetup(moment)
  }, [])

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    if (_account && _account.address) {
      dispatchUpdateEvents()
    }

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [`${prefix}_Token.BalanceReturned`, tokenBalanceReturned],
      [`${prefix}_Farm.StakedArmorReturned`, stakedArmorReturned],
      [`${prefix}_Farm.RewardsOwedReturned`, rewardsOwedReturned],
      [`${prefix}_Farm.LastRewardReturned`, lastRewardReturned],
    ]

    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network])

  useEffect(() => {
    if (account && account.address) {
      let interval = null
      interval = setInterval(() => {
        dispatcher.dispatch({
          type: `${prefix}_Farm.GetRewardsOwed`,
          content: {},
        })
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [account, network])

  const tokenBalanceReturned = () => {
    const _total = store.getStore(`${prefix}_Token_Balance`)
    setTokenBalance(_total)
  }

  const stakedArmorReturned = () => {
    const _total = store.getStore(`${prefix}_Farm_StakedArmor`)
    setStakedArmor(_total)
  }

  const rewardsOwedReturned = () => {
    const _total = store.getStore(`${prefix}_Farm_RewardsOwed`)
    setRewardsOwed(_total)
  }

  const lastRewardReturned = () => {
    try {
      const { apy } = store.getStore(`${prefix}_Farm_LastReward`)
      setApy(apy)
    } catch (e) {}
  }

  const connectionConnected = async () => {
    const _account = store.getStore('account')
    setAccount(_account)
  }

  const connectionDisconnected = () => setAccount(null)

  const errorReturned = () => {}

  const handleClaimRewards = () => {
    dispatcher.dispatch({
      type: `${prefix}_Farm.ClaimRewards`,
      content: {},
    })
  }

  const handleStakeTokensModalSubmit = (data) => {
    dispatcher.dispatch({
      type: `${prefix}_Farm.Stake`,
      content: { amount: data.amount },
    })
  }

  const closeStakeTokensModal = () => {
    setIsStakeTokensModalOpened(false)
  }

  const handleUnStakeTokensModalSubmit = (data) => {
    dispatcher.dispatch({
      type: `${prefix}_Farm.Withdraw`,
      content: { amount: data.amount },
    })
  }

  const closeUnStakeTokensModal = () => {
    setIsUnStakeTokensModalOpened(false)
  }

  const showApy = (days) => {
    if (apy == null || Number.isNaN(apy) || apy == 0) {
      return 0
    }
    return twoDigitFormatter.format(apy / days)
  }

  const getArCoreMonthsDays = () => {
    if (arCoreCredit == 0 || monthlyCost == 0) {
      return '0 Month(s)'
    }
    const _duration = arCoreCredit / monthlyCost
    return moment.duration(_duration, 'months').format(`M [Month(s)], d [Days]`)
  }

  const getMonthlyCost = () => {
    if (monthlyCost == 0) {
      return 0
    }
    return commas(4).format(monthlyCost)
  }

  return (
    <>
      <Wrapper>
        <Row>
          <Column>
            <ColumnHead>
              <NameContainer>
                <LogoContainer>
                  <NameText>{t('Protect.ArcoreBalance.Title')}</NameText>
                  <TooltipGuide
                    text={
                      <>
                        {t('Protect.ArcoreBalance.Title.Tooltip')}
                        <br />
                        <a
                          href="https://armorfi.gitbook.io/armor/products/arcore"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#4AD481' }}
                        >
                          Click here to read more about arCore
                        </a>
                      </>
                    }
                  >
                    <TooltipSpan>
                      <AboutInfoIcon color={colors.disabledText} />
                    </TooltipSpan>
                  </TooltipGuide>
                </LogoContainer>
              </NameContainer>
            </ColumnHead>
            <CurrentRow>
              <InfoText>
                {t('Protect.ArcoreBalance.MonthlyCost')}:
                <TextContent>
                  {isUsdPrimary
                    ? formatUSD(getMonthlyCost() * ethPrice, {
                        compact: true,
                        digits: 1,
                      })
                    : formatETH(getMonthlyCost(), {
                        compact: true,
                        digits: 4,
                      })}{' '}
                  {t('Protect.ArcoreBalance.PerMonth')}
                </TextContent>
                <TooltipGuide
                  text={
                    <>
                      This is the cost per month, <br />
                      based on your current coverage plan
                    </>
                  }
                >
                  <TooltipSpan>
                    <AboutInfoIcon color={colors.disabledText} />
                  </TooltipSpan>
                </TooltipGuide>
              </InfoText>
              <InfoText>
                {t('Protect.ArcoreBalance.ArcoreBalanceEth')}:
                <ColoredValue contrast active={arCoreCredit > 0}>
                  <ValueInfo>
                    {isUsdPrimary
                      ? formatUSD(arCoreCredit * ethPrice, {
                          compact: true,
                          digits: 1,
                        })
                      : formatETH(arCoreCredit, { compact: true, digits: 4 })}
                  </ValueInfo>{' '}
                  / {getArCoreMonthsDays()}
                </ColoredValue>
                <TooltipGuide
                  text={
                    <>
                      {t('Protect.ArcoreBalance.ArcoreBalanceEth.Tooltip')}
                      <br />
                      <TooltipLink
                        href="https://armorfi.gitbook.io/armor/products/arcore#arcore-credit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to read more about arCore
                      </TooltipLink>
                    </>
                  }
                >
                  <TooltipSpan>
                    <AboutInfoIcon color={colors.disabledText} />
                  </TooltipSpan>
                </TooltipGuide>
              </InfoText>
            </CurrentRow>
            <ActionWrapper>
              <ActionButtonNew
                secondary="true"
                variant="contained"
                onClick={handleOpenWithdrawModal}
              >
                <ButtonText className="secondary">
                  {t('Protect.Withdraw')}
                </ButtonText>
                <TooltipGuide text={t('Protect.Withdraw.Tooltip')}>
                  <TooltipSpan>
                    <AboutInfoIcon color={colors.secondary} />
                  </TooltipSpan>
                </TooltipGuide>
              </ActionButtonNew>
              <ActionButtonNew
                secondary="true"
                variant="contained"
                onClick={handleOpenTopupModal}
              >
                <ButtonText className="secondary">
                  {t('Protect.TopUp')}
                </ButtonText>
                <TooltipGuide
                  text={
                    <>
                      Click here to add more ETH
                      <br /> to your arCore Smart Cover Credit.
                    </>
                  }
                >
                  <TooltipSpan>
                    <AboutInfoIcon color={colors.secondary} />
                  </TooltipSpan>
                </TooltipGuide>
              </ActionButtonNew>
            </ActionWrapper>
          </Column>
          <Column>
            <ColumnHead>
              <NameContainer>
                <LogoContainer>
                  <Logo
                    src={require(`../../assets/armor-circle-logo.svg`)}
                    alt="contract icon"
                  />
                  <NameText>{title}</NameText>
                  <TooltipGuide
                    text={
                      'The rewards APY below is based on the cost of your cover (not the covered amount)'
                    }
                  >
                    <TooltipSpan>
                      <AboutInfoIcon color={colors.disabledText} />
                    </TooltipSpan>
                  </TooltipGuide>
                </LogoContainer>
              </NameContainer>
            </ColumnHead>
            {comingSoon ? (
              <FooterWrapper>
                <FooterText>{t('UtilizationFarming.ComingSoon')}</FooterText>
              </FooterWrapper>
            ) : (
              <>
                <CurrentRow>
                  <CurrentTitle compare="true">
                    APY:
                    <TextContent>{showApy(1)}%</TextContent>
                  </CurrentTitle>
                </CurrentRow>

                <FooterActionButton>
                  <ClaimFooterText>
                    {t('UtilizationFarming.ClaimableRewards')}:
                    <ClaimFooterValue claimValue={rewardsOwed > 0}>
                      {threeDigitFormatter.format(rewardsOwed)}
                    </ClaimFooterValue>
                  </ClaimFooterText>
                  {rewardsOwed > 0 && (
                    <ActionButtonNew
                      secondary="true"
                      variant="contained"
                      onClick={() => handleClaimRewards()}
                    >
                      <ButtonText className="secondary">
                        {t('Rewards.Item.ClaimRewards')}
                      </ButtonText>
                    </ActionButtonNew>
                  )}
                </FooterActionButton>
              </>
            )}
          </Column>
        </Row>

        <AvailableWalletInfo
          text={`${t('Protect.ArcoreBalance.WalletBalanceEth')}:`}
          value={
            isUsdPrimary
              ? formatUSD(ethBalance * ethPrice, { compact: true, digits: 2 })
              : formatETH(ethBalance, { compact: true, digits: 4 })
          }
          center={false}
        />

        <StakeTokensModal
          totalAssets={tokenBalance}
          closeModal={closeStakeTokensModal}
          handleSubmit={handleStakeTokensModalSubmit}
          isModalOpened={isStakeTokensModalOpened}
        />
        <UnStakeTokensModal
          totalAssets={stakedArmor}
          closeModal={closeUnStakeTokensModal}
          handleSubmit={handleUnStakeTokensModalSubmit}
          isModalOpened={isUnStakeTokensModalOpened}
        />
      </Wrapper>
    </>
  )
}

export default withTranslation()(
  withRouter(withTheme(BaseUtilizationFarmingCombined))
)
