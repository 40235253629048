import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CoverContainer } from '../../components/common/CoverContainer'

export const Container = styled(CoverContainer)`
  margin-top: 32px;
  overflow: hidden;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 17px 0;
  flex-wrap: wrap;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
  @media screen and (max-width: 448px) {
    flex-direction: column;
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
`
export const TableContainerStyled = styled(TableContainer)`
  margin-top: 25px;
  overflow-x: unset;
  border-radius: unset;
  box-shadow: none;
  background: transparent;
  & td {
    border: none;
  }
  & th {
    border: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
export const TableStyledWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const TableTitle = styled(TableCell)`
  font-size: 14px;
  line-height: 19px;
  color: ${(p) => p.theme.colors.secondary};
  padding: 15px 7px;
  font-family: 'Open Sans', sans-serif;

  @media screen and (min-width: 1080px) {
    white-space: nowrap;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 5px;
  }
`
export const TableMontlyTitle = styled(TableTitle)`
  @media screen and (min-width: 1080px) {
    white-space: nowrap;
  }
`
export const AssetTitle = styled(TableTitle)`
  padding: 15px 7px 15px 6px;
  @media screen and (max-width: 600px) {
    padding: 8px 5px;
  }
`
export const Row = styled(TableRow)`
  min-height: 51px;
  background: ${(p) =>
    p.checked
      ? p.theme.colors.selectedCoverRow
      : p.orangewarning
      ? p.theme.colors.selectedWarningRow
      : p.redwarning
      ? p.theme.colors.selectedErrorRow
      : 'transparent'};

  @media screen and (max-width: 600px) {
    min-height: unset;
  }
`
export const AssetsLogo = styled(TableCell)`
  padding: 8px 7px 8px 6px;

  @media screen and (max-width: 600px) {
    padding: 6px 5px;
  }
`
export const AssetWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
`
export const AssetsTitle = styled.div`
  display: flex;
  flex-direction: column;
`
export const AssetsName = styled.h5`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  flex-wrap: wrap;
  color: ${(p) => p.theme.colors.active};
  display: flex;
  align-items: center;

  & span {
    display: flex;
    margin-left: 5px;
  }
  & svg {
    max-width: 18px;
    height: 18px;
    width: 100%;
  }
`
export const NoAssetsTableRow = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.primaryDefault};
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
`
export const Checkmark = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  border: 1px solid ${(p) => p.theme.colors.active};
  box-sizing: border-box;
  border-radius: 3px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px;
    background: ${(p) => p.theme.colors.active};
    border-radius: 1px;
    opacity: 0;
  }
`
export const AssetCheckbox = styled(Checkbox)`
  height: 35px;
  width: 35px;
  position: relative;
  ${(p) =>
    p.isnotempty
      ? css`
          ~ ${Checkmark} {
            &::before {
              opacity: 0.2;
            }
          }
        `
      : css``}
  &:hover {
    ~ ${Checkmark} {
      &::before {
        opacity: 0.2;
      }
    }
  }
  &.Mui-checked {
    ~ ${Checkmark} {
      &::before {
        opacity: 1;
      }
    }
  }
  & .MuiSvgIcon-root {
    fill: ${(p) => p.theme.colors.active};
    display: none;
  }
`
export const CheckboxLabel = styled(FormControlLabel)`
  margin: 0;
  position: relative;
`
export const Cell = styled(TableCell)`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 8px 7px;
  color: ${(p) => p.theme.colors.secondary};

  @media screen and (max-width: 600px) {
    padding: 6px 5px;
  }
`
export const LargeCell = styled(Cell)`
  padding: 6px 7px;

  @media screen and (max-width: 600px) {
    padding: 5px;
  }
`
export const CheckboxCell = styled(Cell)`
  padding: 2px 0px 0px 6px;
`
export const AssetLogoImg = styled.img`
  border-radius: 12px;
  height: 24px;
  width: 24px;
  margin-right: 6px;
`
export const SpanValue = styled.span`
  font-weight: normal;
`
export const TooltipSpan = styled.span`
  height: 16px;
  max-width: 16px;
  min-width: 16px;
  margin-left: 6px;
  width: 100%;
  display: flex;
  & svg {
    height: 16px;
    max-width: 16px;
    width: 100%;
    min-width: 16px;
  }
`
export const WarningIconWrapper = styled.span`
  display: flex;
  margin-right: 6px;
  & svg {
    height: 18px;
    width: 18px;
  }
`
export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.defaultArrow};
  border-radius: 16px;
  overflow: hidden;
  margin: 30px 0 -20px;
  width: 100px;
  position: relative;
  height: 32px;

  &::before {
    content: '';
    width: 48px;
    height: calc(100% - 4px);
    background: ${(p) => p.theme.colors.active};
    position: absolute;
    z-index: 1;
    border-radius: 16px;
    top: 2px;
    transition: right 0.3s;

    ${(p) =>
      p.primary
        ? css`
            right: 50px;
          `
        : css`
            right: 2px;
          `}
  }
`
export const ToggleSwitchButton = styled.div`
  width: 50px;
  height: 32px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${(p) => p.theme.colors.secondary};
`
export const ActionButton = styled(Button)`
  border: 1px solid ${(p) => p.theme.colors.secondary};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 6px 15px;
  min-width: unset;
  max-width: fit-content;
  margin: 30px auto 0;
  display: block;
`
export const ButtonText = styled.h5`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.colors.secondary};
`
export const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: ${(p) => (p.center ? '12px' : '14px')};
  line-height: ${(p) => (p.center ? '16px' : '19px')};
  text-align: ${(p) => (p.center ? 'center' : 'left')};
  color: ${(p) => p.theme.colors.secondary};
  max-width: 585px;
  width: 100%;
  margin: 14px auto 0;
  filter: ${(p) => (p.blur ? 'blur(2px)' : 'none')};
`
export const ModalWrapper = styled.div`
  max-width: 695px;
  width: 100%;
  margin: 0 auto;
`
