import React from 'react'
import { Logo, TitleWrapper } from './styled'
import { BoxTitleText } from '../../../../components/common/BoxTitleText'

function ShieldGroupTitle({ title, logo }) {
  return (
    <TitleWrapper>
      <Logo src={require(`../../../../assets/${logo}`)} alt={`${title} logo`} />
      <BoxTitleText>{title}</BoxTitleText>
    </TitleWrapper>
  )
}

export default ShieldGroupTitle
