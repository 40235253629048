import styled from 'styled-components'

export const HeaderCellWrapper = styled.div`
  display: flex;
  justify-content: ${(p) => p.align};
  align-items: center;

  & svg {
    transition: all 0.2s;
    transform: ${(p) => (p.transform === 'desc' ? 'none' : 'rotate(180deg)')};
    height: 10px;
    width: 10px;
    margin-left: 8px;
  }
`
