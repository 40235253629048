export const RCAVaultsEvent = (prefix) => {
  return {
    Mint: `${prefix}.Mint`,
    MintCompleted: `${prefix}.MintCompleted`,

    Withdrawal: `${prefix}.Withdrawal`,
    WithdrawalCompleted: `${prefix}.WithdrawalCompleted`,

    DepositApproveCompleted: `${prefix}.DepositApproveCompleted`,
    WithdrawApproveCompleted: `${prefix}.WithdrawApproveCompleted`,

    GetUnderlyingTokenBalance: `${prefix}.GetUnderlyingTokenBalance`,
    UnderlyingTokenBalanceReturned: `${prefix}.UnderlyingTokenBalanceReturned`,

    GetShieldBalance: `${prefix}.GetShieldBalance`,
    ShieldBalanceReturned: `${prefix}.ShieldBalanceReturned`,

    GetRcaTokenValueOfPToken: `${prefix}.GetRcaTokenValueOfPToken`,
    RcaTokenValueOfPTokenReturned: `${prefix}.RcaTokenValueOfPTokenReturned`,

    GetWithdrawRequests: `${prefix}.GetWithdrawRequests`,
    WithdrawRequestsReturned: `${prefix}.WithdrawRequestsReturned`,

    FinalizeWithdraw: `${prefix}.FinalizeWithdraw`,
    FinalizeWithdrawCompleted: `${prefix}.FinalizeWithdrawCompleted`,
  }
}
