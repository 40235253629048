import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withTheme } from 'styled-components'
import AboutInfoIcon from '../../components/icons/AboutInfoIcon'
import ArrowIcon from '../../components/icons/ArrowIcon'
import { BoxTitleText } from '../../components/common/BoxTitleText'
import { HeaderCellWrapper } from '../../components/common/HeaderCellWrapper'
import { TitleWrapper } from '../../components/common/TitleWrapper'
import TooltipGuide from '../../components/common/tooltipGuide/TooltipGuide'
import { TooltipSpan } from '../../components/common/TooltipSpan'
import {
  Box,
  Header,
  TableContainerStyled,
  TableStyled,
  TitleCell,
} from './styled'
import Search from '../../components/common/search/Search'
import VaultRow from './ValutRow'

const SORTABLE_FIELDS = ['name', 'apy', 'assets', 'underlyingTokenBalance']
const columns = ['Name', 'APY', 'Assets', 'Deposit']

const Opportunities = ({
  t,
  theme,
  data,
  sort,
  account,
  setModalText,
  setIsDepositing,
  setIsWithdrawing,
}) => {
  const { colors } = theme
  const [sortBy, setSortBy] = useState(SORTABLE_FIELDS[1])
  const [sortDirection, setSortDirection] = useState('desc') // asc | desc

  const handleClickSortBy = (_sortBy) => {
    let _sortDirection = sortDirection
    if (_sortBy === sortBy) {
      _sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortDirection(_sortDirection)
    } else {
      setSortBy(_sortBy)
    }
    sort(_sortBy, _sortDirection)
  }

  return (
    <Box>
      <Header>
        <TitleWrapper>
          <BoxTitleText>{t('Dashboard.Opportunities')}</BoxTitleText>
          <TooltipGuide text={t('Stake.StakedTooltip')}>
            <TooltipSpan>
              <AboutInfoIcon color={colors.disabledText} />
            </TooltipSpan>
          </TooltipGuide>
        </TitleWrapper>
        <Search placeholder={'Vault Name'} />
      </Header>

      <TableContainerStyled
        novalue={!data || data.length === 0 ? 1 : 0}
        component={Paper}
      >
        <TableStyled
          novalue={!data || data.length === 0 ? 1 : 0}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TitleCell
                  key={column}
                  showarrow={sortBy === SORTABLE_FIELDS[index] ? 1 : 0}
                  onClick={() => handleClickSortBy(SORTABLE_FIELDS[index])}
                >
                  <HeaderCellWrapper transform={sortDirection}>
                    {t(`Dashboard.Token.${column}`)}
                    <ArrowIcon color={colors.secondary} />
                  </HeaderCellWrapper>
                </TitleCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length !== 0 &&
              data.map((r, rIndex) => (
                <VaultRow
                  key={rIndex}
                  account={account}
                  columns={SORTABLE_FIELDS}
                  r={r}
                  disableWithdraw={true}
                  setModalText={setModalText}
                  setIsDepositing={setIsDepositing}
                  setIsWithdrawing={setIsWithdrawing}
                />
              ))}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>
    </Box>
  )
}

export default withTranslation()(withTheme(Opportunities))
