import React from 'react'
import { ActionWrapper, ActionCell } from './styled'
import Button from '../../components/common/button/Button'

const RcaActionButtonCell = ({
  onMint,
  onRedeem,
  isConnected,
  isLoading,
  disableWithdraw,
}) => {
  return isConnected ? (
    <ActionCell align="right">
      <ActionWrapper>
        <Button
          buttonText="deposit"
          isDisabled={isLoading}
          onClick={onMint}
          bordered={true}
          margin="0 7px"
        />
        {!disableWithdraw && (
          <Button
            buttonText="withdraw"
            isDisabled={isLoading}
            onClick={onRedeem}
            bordered={true}
            margin="0 7px"
          />
        )}
      </ActionWrapper>
    </ActionCell>
  ) : (
    <></>
  )
}

export default RcaActionButtonCell
